<template>
    <app-page v-if="currentContract"
              :title="pageTitle"
              :back="() => { $router.push({ name: 'documents' }) }">
        <section>
            <center-container col-class="col-xs-no-gutter">
                <b-card class="mb-0 mb-sm-5">
                    <template #header>
                        <p class="h4 text-center">Votre contrat n°{{ currentContract.num_contract }}</p>
                    </template>
                    <div>
                        <div>
                            <strong>Statut du contrat</strong> : {{ currentContract.state_name }}
                        </div>
                        <div class="mt-1">
                            <strong>Date de début :</strong>
                            <span v-if="currentContract.start_date">
                                {{ $moment(currentContract.start_date).format('DD/MM/YYYY') }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </div>
                        <div class="mt-1">
                            <strong>Date de fin :</strong>
                            <span v-if="currentContract.end_date">
                                {{ $moment(currentContract.end_date).format('DD/MM/YYYY') }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </div>
                    </div>
                    <contract-detail-family v-if="currentContract.can_have_recipients"
                                            :currentContract=currentContract></contract-detail-family>
                    <contract-detail-merci v-else
                                           :currentContract=currentContract></contract-detail-merci>
                </b-card>
            </center-container>
        </section>
    </app-page>
</template>

<script>
import AppPage from '@/components/layout/AppPage'
import CenterContainer from '@/components/layout/CenterContainer'
import ContractDetailFamily from '@/components/pages/contrats/ContractDetailFamily'
import ContractDetailMerci from '@/components/pages/contrats/ContractDetailMerci'
import Consignes from "@/components/pages/contrats/consignes/Consignes";

export default {
    components: {CenterContainer, AppPage, ContractDetailFamily, ContractDetailMerci, Consignes},
    data() {
        return {
            currentContract: null
        }
    },
    computed: {
        pageTitle() {
            if (!this.currentContract) return
            return this.currentContract.state_id == 7 ? 'Vos anciens contrats' : 'Vos contrats'
        }
    },
    created() {
        this.currentContract = this.$route.params.currentContract

        if (!this.currentContract) return this.$router.push({name: 'contrats'})
    }
}
</script>