<template>
    <div class="children-manager">
        <p class="text-center py-2">Vos enfants</p>
        <b-form v-if="children.length > 0" @submit.prevent="submitAction">
            <div v-for="(child, index) in children"
                 class="d-flex flex-wrap pt-3 pb-2"
                 :class="{'border-gray border-bottom': index < children.length - 1}"
                 :key="`child-${index}`">
                <div v-for="(input, key) in inputs"
                     class="pb-2 w-50 pxa-2"
                     :key="`input-${index}-${key}`">
                    <div class="activable-input">
                        <component v-model="child[key]"
                                   :is="input.component"
                                   :ref="`${key}_${index}`"
                                   class="activable-input__input text-nowrap text-truncate"
                                   :type="input.type"
                                   :placeholder="input.placeholder"
                                   :disabled="!children[index].editing.includes(key) && !input.noDisable"
                                   :required="!!input.required"
                                   @change="() => { if (input.hasOwnProperty('changeAction')) input.changeAction(index) }"></component>
                        <button class="activable-input__btn"
                                :class="{'activable-input__btn--active': children[index].editing.includes(key)}"
                                @click.prevent="() => { editAction(index, key) }">
                            <component v-show="!children[index].editing.includes(key)"
                                       :is="input.icon"
                                       class="activable-input__icon"></component>
                            <b-icon-x-square v-show="children[index].editing.includes(key)"
                                             class="activable-input__icon"></b-icon-x-square>
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center pt-3">
                <b-button :disabled="!canUpdate"
                          size="lg"
                          variant="primary"
                          type="submit">Modifier
                </b-button>
            </div>
        </b-form>
        <p v-else class="text-center">Aucun enfant enregistré</p>
        <div class="d-flex flex-column align-items-center pt-5">
            <p>Une demande complémentaire ?</p>
            <b-link class="mt2"
                    to="/contact">Cliquez ici
            </b-link>
        </div>
    </div>
</template>

<script>
import {BIconCamera, BIconPencilSquare, BIconPlusCircleFill, BIconXSquare} from 'bootstrap-vue'
import contratsService from "@/services/contratsService";
import AlertHandler from "@/alertHandler";

export default {
    name: 'contract-detail-family',
    components: {BIconPencilSquare, BIconCamera, BIconXSquare, BIconPlusCircleFill},
    computed: {
        // ...mapState({
        //   currentContract: state => state.currentContract
        // }),
        canUpdate() {
            return true
        }
    },
    props: ['currentContract'],
    data() {
        return {
            children: [],
            inputs: {
                recipient_lastname: {
                    component: 'b-form-input',
                    icon: 'b-icon-pencil-square',
                    placeholder: 'Nom',
                    type: 'text',
                    required: true,
                    edit: (id) => {
                        this.$refs[`recipient_lastname_${id}`][0].$el.focus()
                    },
                    isValid(value) {
                        return true
                    }
                },
                recipient_firstname: {
                    component: 'b-form-input',
                    icon: 'b-icon-pencil-square',
                    placeholder: 'Prénom',
                    type: 'text',
                    required: true,
                    edit: (id) => {
                        this.$refs[`recipient_firstname_${id}`][0].$el.focus()
                    },
                    isValid(value) {
                        return true
                    }
                },
                recipient_birth_day: {
                    component: 'b-form-input',
                    icon: 'b-icon-pencil-square',
                    type: 'date',
                    required: true,
                    edit: (id) => {
                        this.$refs[`recipient_birth_day_${id}`][0].$el.focus()
                    },
                    isValid(value) {
                        return true
                    }
                },
                // image: {
                //   component: 'b-form-file',
                //   icon: 'b-icon-camera',
                //   placeholder: '+ Photo',
                //   noDisable: true,
                //   changeAction: (id) => {
                //     if (this.children[id].image) return
                //     this.editAction(id, 'image')
                //   },
                //   edit: (id) => {
                //     this.$refs[`image_${id}`][0].$el.click()
                //   },
                //   isValid (value) {
                //     return true
                //   }
                // }
            }
        }
    },
    mounted() {
        if (!this.$props.currentContract) return
        // Make new instance of children Array so it can be modified and compared to currentContract.children
        this.$props.currentContract.recipients.forEach(child => {
            this.children.push({
                ...child,
                editing: [],
                image: null
            })
        })
    },
    methods: {
        editAction(index, key) {
            if (!this.children[index].editing.includes(key)) {
                this.children[index].editing.push(key)
                this.$nextTick(() => {
                    this.inputs[key].edit(index)
                })
                return
            }
            this.children[index].editing.splice(this.children[index].editing.indexOf(key), 1)
            this.children[index][key] = this.currentContract.recipients[index][key]
        },
        async submitAction() {
            const childrenToUpdate = [];
            this.children.forEach((child, index) => {
                Object.keys(child).forEach(key => {
                    if (this.currentContract.recipients[index]?.[key] && this.currentContract.recipients[index]?.[key] != child[key]) {
                        childrenToUpdate.push({...child, model: this.currentContract.recipients[index]});
                    }
                })
            });

            let hasError = false;

            for (const child of childrenToUpdate) {
                try {
                    const params = {
                        idrecipient: child.idrecipient,
                        recipient_birth_day: child.recipient_birth_day,
                        recipient_firstname: child.recipient_firstname,
                        recipient_lastname: child.recipient_lastname
                    };

                    const res = await contratsService.updateRecipient(params);

                    if (!res) {
                        hasError = true;
                        AlertHandler.show({code: 4016});
                    } else {
                        Object.assign(child.model, params);
                    }
                } catch (error) {
                    hasError = true;
                    AlertHandler.show({code: 4017, error});
                }
            }

            if (!hasError)
                AlertHandler.show({title: 'Succès', message: 'Mise à jour effectuée avec succès'});
        }
    }
}
</script>